/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
const mock = [
	{ 
		title:`Versatile`,
		primary:'',
		desc:`Although Pipy is mostly used as a high-performance reverse proxy, the real power of Pipy relies on providing you a range of basic pluggable building blocks, aka. "filters", and not imposing any restrictions on how you combine them. It's entirely up to you. We've seen people using Pipy to convert protocols, record network traffic, sign/verify messages, trigger serverless functions, health-check servers, and more.`,
		feature:'/img/point1.svg',
		layout:'left'
	},
	{ 
		title:`Fast`,
		primary:'',
		desc:`Pipy is written in C++. It leverages asynchronous network I/O. Allocated resources are pooled and reused. Data is transferred internally by pointers whenever possible. It's fast in every way.`,
		feature:'/img/point2.svg',
		layout:'right'
	},
	{ 
		title:`Tiny`,
		primary:'',
		desc:`A build of Pipy with no built-in GUI frontend resources gives you an executable merely around 10MB with zero external dependencies. You'll experience the fastest download and startup times with Pipy.`,
		feature:'/img/point3.svg',
		layout:'left'
	},
	{ 
		title:`Programmable`,
		primary:'',
		desc:`At the core, Pipy is a script engine running PipyJS, a tailored version from ECMA standard JavaScript. By speaking the planet's most widely used programming language, Pipy gives you unparalleled freedom over what you have in other products based on YAML configuration files and the like.`,
		feature:'/img/point4.svg',
		layout:'right'
	},
	{ 
		title:`Open`,
		primary:'',
		desc:`Pipy is more open than open source. It doesn’t try to hide details in a black box. You'll always know what you are doing. It might sound a bit daunting but fear not, it doesn’t require a rocket scientist to understand how the different parts work together. In fact, you’ll only have more fun as you have complete control over everything.`,
		feature:'/img/point5.svg',
		layout:'left'
	},
]
const Teaser = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
	  {mock.map((item, i) => (
			<Box key={i}>
			{( item.layout=='left'||!isMd )
			? <Grid  container spacing={4} direction={isMd ? 'row' : 'column'}>
				<Grid
				  item
				  container
				  alignItems={'center'}
				  xs={12}
				  md={6}
				  data-aos={isMd ? 'fade-right' : 'fade-up'}
				>
				  <Box>
					<Typography
					  sx={{
						textTransform: 'uppercase',
						fontWeight: 'medium',
					  }}
					  gutterBottom
					  color={'secondary'}
					>
					  {item.top}
					</Typography>
					<Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
					  {item.title}.{' '}
					  <Typography color="primary" variant="inherit" component="span">
						{item.primary}
					  </Typography>
					</Typography>
					<Typography variant={'h6'} component={'p'} color={'text.secondary'}>
					  {item.desc}
					</Typography>
				  </Box>
				</Grid>
				<Grid
				  item
				  container
				  justifyContent="center"
				  alignItems="center"
				  xs={12}
				  md={6}
				>
				  <Box maxWidth={490} width={1}>
					<Box
					  component={'img'}
					  src={item.feature}
					  width={1}
					  height={1}
						alt={item.title}
					  sx={{
						filter:
						  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
					  }}
					/>
				  </Box>
				</Grid>
			  </Grid>
			: <Grid key={i} container spacing={4} direction={isMd ? 'row' : 'column'}>
				<Grid
					item
					container
					justifyContent="center"
					alignItems="center"
					xs={12}
					md={6}
				  >
					<Box maxWidth={490} width={1}>
					<Box
					  component={'img'}
					  src={item.feature}
					  width={1}
					  height={1}
						alt={item.title}
					  sx={{
						filter:
						  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
					  }}
					/>
					</Box>
				</Grid>
				<Grid
				  item
				  container
				  alignItems={'center'}
				  xs={12}
				  md={6}
				  data-aos={isMd ? 'fade-left' : 'fade-up'}
				>
				  <Box>
					<Typography
					  sx={{
						textTransform: 'uppercase',
						fontWeight: 'medium',
					  }}
					  gutterBottom
					  color={'secondary'}
					>
					  {item.top}
					</Typography>
					<Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
					  {item.title}.{' '}
					  <Typography color="primary" variant="inherit" component="span">
						{item.primary}
					  </Typography>
					</Typography>
					<Typography variant={'h6'} component={'p'} color={'text.secondary'}>
					  {item.desc}
					</Typography>
				  </Box>
				</Grid>
			  </Grid>
				}
				</Box>
      ))}
    </Box>
  );
};

export default Teaser;
