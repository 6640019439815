import React from 'react';
import Typed from 'react-typed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import { Link } from "gatsby"
import RouterLink from "components/RouterLink"
import Container from 'components/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const mock = [
  {
    title: 'Pipy',
		logo:['/img/pipy.png','/img/pipy-white.png'],
    desc: [
			'<b>Pipy</b> is a high performance programmable proxy for the cloud, edge and IoT. Its core is written in <b>C++</b> with excellent cross-platform capability. It is both high performance and low resource. Pipy comes with a built-in <b>JavaScript</b> engine that allows easy custom logic implementation with the simple JS syntax, greatly reducing the complexity in <b>high-performance</b> network programming.',
			'It is suitable for a variety of hardware architectures including <b>x86</b>, <b>ARM64</b>, <b>Loongson</b> and <b>RISC-V</b>. It is also compatible with various other operating systems besides <b>Linux</b>.'
		],
		btn:'Pipy Home',
		path:'/pipy',
		prefix: '',
		words: [
			'High-performance programmable proxy', 
			'Developed in C++',
			'60K lines of code',
			'Built-in JavaScript engine: PipyJS',
			'MIT-like license'
		]
  }
];
const Hero = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom, ${alpha(
          theme.palette.background.paper,
          0,
        )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
        backgroundRepeat: 'repeat-x',
        position: 'relative',
      }}
    >
      <Box paddingY={{ xs: 0, sm: '4rem', md: '8rem' }}>
        <Container>
					{mock.map((item, i) => (
						<Box key={i}>
							<Box maxWidth={{ xs: 1, sm: '50%' }} sx={{
								minHeight: '250px'
							}}>
								<Typography
									variant="h3"
									color="text.primary"
									gutterBottom
									sx={{
										fontWeight: 700,
									}}
								>
									{ item.logo && (
										<Box
											display={'inline-block'}
											component={'span'}
											height={{ xs: 70, md: 70 }}
											sx={{
												verticalAlign: 'middle',
												position: 'relative',
												marginRight: '20px'
											}}
										>
											<Box
												component={'img'}
												sx={{
													verticalAlign: 'middle'
												}}
												src={
													mode === 'light'
														? item.logo[0]
														: item.logo[1]
												}
												height={1}
											/>
										</Box>
									)}
									<Box
										display={'inline-block'}
										sx={{
											verticalAlign: 'middle'
										}}
									>
										{item.title}
									</Box>
									<br />
									{item.prefix?(
										<Typography
											color="text.primary"
											component={'span'}
											variant="h5"
											sx={{
												marginRight: '10px',
											}}
										>
										{item.prefix}
										</Typography>
									):null}
									{item.words?(
										<Typography
											color={'primary'}
											component={'span'}
											variant="h5"
										>
											<Typed
												strings={item.words}
												typeSpeed={80}
												loop={true}
											/>
										</Typography>
									):null}
								</Typography>
								{item.desc.map((desc, j) => (
									<Typography
										key={j}
										variant="h7"
										component="p"
										color="text.secondary"
										sx={{ fontWeight: 400,marginBottom:'15px' }}
										dangerouslySetInnerHTML={{__html:desc}}
									>
									</Typography>
								))}
								
								<Box
								  display="flex"
								  flexDirection={{ xs: 'column', sm: 'row' }}
								  alignItems={{ xs: 'stretched', sm: 'flex-start' }}
								  marginTop={4}
								>
									<Button
										component={Link}
										variant="contained"
										color="primary"
										size="large"
										fullWidth={isMd ? false : true}
										to={RouterLink('/pipy/docs/getting-started/quick-start')}
									>
										Quick start
									</Button>
								  <Box
								    marginTop={{ xs: 2, sm: 0 }}
								    marginLeft={{ sm: 2 }}
								    width={{ xs: '100%', md: 'auto' }}
								  >
										<Button
											component={Link}
											to={RouterLink('/pipy/docs')}
											variant="outlined"
											color="primary"
											size="large"
											fullWidth={isMd ? false : true}
										>
											Documentation
										</Button>
								  </Box>
								</Box>
							</Box>
						</Box>
					))}
        </Container>
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <Box
            display={'flex'}
            width={'50rem'}
            left={'50%'}
            top={0}
            position={'absolute'}
            sx={{ transform: 'translate3d(10%, 40%, 0)' }}
          >
		  
		  <Box
		      component={LazyLoadImage}
		      effect="blur"
					alt="high performance web server,programmable ingress,programmable egress,software loadbalancer,general purpose sidecar"
		      src={
		        theme.palette.mode === 'dark' ? '/img/screen-img.svg' : '/img/screen-img.svg'
		      }
		      width={620}
		      maxWidth={620}
		    />
          </Box>
        </Box>
      </Box>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: '100%',
          marginBottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Hero;
